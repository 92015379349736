"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfig = getConfig;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
function getConfig() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _p$config = p.config,
    config = _p$config === void 0 ? {} : _p$config;
  var commonConfig = config.common || {};
  var common = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, commonConfig), {}, {
    siteName: "Hatchet on the table",
    description: "Hatchet on the table"
  });
  return {
    config: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, config), {}, {
      common: common
    })
  };
}